import React from "react";

export default class Resume extends React.Component {
  render() {
    return (
      <div>
        <p>Resume</p>
      </div>
    );
  }
}
